import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import withMddBadge from '@shared/ui/badges/MddBadge/withMddBadge';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { Body, BodyLargeSemiBold, BodyMedium, } from '@components/styled/Typography';
import { ContainerColumn, ContainerRow } from '@components/styled';
import PortfolioGraph from '../../../components/IncomeGraph';
import PortfolioFollowersPreview from '../../../components/FollowersPreview';
import PortfolioMonthlyReturn from '../../../components/MonthlyReturn';
import PastMonthProfitDates from '../../../components/PastMonthProfitDates';
const MddBadge = withMddBadge(BodyMedium);
const IncomeBadgeMedium = withPercentageIncomeBadge(BodyMedium);
const IncomeBadgeEmphasized = withPercentageIncomeBadge(BodyLargeSemiBold);
const RowSpaceBetween = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const WholePeriodDetailsContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'end',
    gap: props.theme.spacing_sizes.s,
}));
const WholePeriodDetailsRow = styled(ContainerRow)(props => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const ProfitGraph = styled.div(() => ({
    position: 'relative',
    width: '100%',
    height: 55,
}));
const FollowersMonthlyReturn = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const PastMonthProfitContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'start',
    gap: props.theme.spacing_sizes.s,
}));
const PastMonthProfit = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const FollowersMonthlyReturnLabel = styled(Body, { shouldForwardProp: (propName) => isPropValid(propName) })(props => ({
    textAlign: props.alignRight ? 'right' : 'left',
    lineHeight: '17px',
}));
const FollowersMonthlyReturnContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const PortfolioCardContent = ({ portfolio, }) => {
    var _a;
    const { t } = useTranslation();
    const userSubscribed = useMemo(() => !!portfolio.user_subscription_summary, [portfolio]);
    const monthlyReturnLabel = () => (userSubscribed ?
        t('portfolio.your_past_month_return') :
        t('portfolio.followers_avg_monthly_return'));
    return (_jsxs(_Fragment, { children: [_jsxs(RowSpaceBetween, { children: [_jsxs(PastMonthProfitContainer, { children: [_jsxs(PastMonthProfit, { children: [_jsx(IncomeBadgeEmphasized, { badgeVariant: 'tinted', badgeSize: 'large', percentageIncome: portfolio.past_month_difference }), _jsx(Body, { children: t('portfolio.past_month') })] }), _jsx(PastMonthProfitDates, { startDate: portfolio.past_month_start_date, endDate: portfolio.past_month_end_date })] }), _jsxs(WholePeriodDetailsContainer, { children: [_jsxs(WholePeriodDetailsRow, { children: [_jsx(IncomeBadgeMedium, { badgeVariant: 'plain', percentageIncome: portfolio.overall_difference }), _jsx(BodyMedium, { children: `${portfolio.whole_period_days_count}${t('portfolio.days_short')}.` })] }), _jsxs(WholePeriodDetailsRow, { children: [_jsx(MddBadge, { mdd: ((_a = portfolio.maxDrawdown) === null || _a === void 0 ? void 0 : _a.yearly) || 0, badgeVariant: 'plain', badgeSize: 'medium' }), _jsx(BodyMedium, { children: t('portfolio.mdd.short') })] })] })] }), _jsx(ProfitGraph, { children: _jsx(PortfolioGraph, { width: 376, height: 40, colorVariant: Number(portfolio.overall_difference) > 0 ?
                        'positive' :
                        'negative' }) }), _jsxs(FollowersMonthlyReturn, { children: [_jsxs(FollowersMonthlyReturnContainer, { children: [_jsx(FollowersMonthlyReturnLabel, { children: t('portfolio.followers_label') }), _jsx(PortfolioFollowersPreview, { portfolio: portfolio })] }), _jsxs(FollowersMonthlyReturnContainer, { children: [_jsx(FollowersMonthlyReturnLabel, { alignRight: true, children: monthlyReturnLabel() }), _jsx(PortfolioMonthlyReturn, { portfolio: portfolio, userSubscribed: userSubscribed })] })] })] }));
};
export default PortfolioCardContent;
